var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.returnPrevious()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(!_vm.readonly())?_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"carDealerForm","color":"primary","type":"submit","loading":_vm.isCarDealerSubmitting}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"carDealer","fluid":"","tag":"section"}},[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"carDealerForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-wallet-travel"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('car_dealer.form.title'))+" ")])],1),_c('v-row',{staticClass:"pt-7 pb-5"},[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.brand'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.$appConfig.brands,"return-object":"","item-text":"name","label":_vm.$t('car_dealer.form.brand') + '*',"outlined":"","error-messages":errors,"disabled":_vm.readonly()},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"ml-3",attrs:{"error-messages":errors,"label":_vm.$t('car_dealer.form.code')+ '*',"outlined":"","disabled":_vm.readonly()},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"ml-md-3",attrs:{"error-messages":errors,"label":_vm.$t('car_dealer.form.name')+ '*',"outlined":"","disabled":_vm.readonly()},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.car_dealer_region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.$appConfig.carDealerRegions,"label":_vm.$t('car_dealer.form.car_dealer_region')+ '*',"outlined":"","error-messages":errors,"readonly":_vm.readonly()},model:{value:(_vm.carDealerRegion),callback:function ($$v) {_vm.carDealerRegion=$$v},expression:"carDealerRegion"}})]}}])})],1),_c('v-col',{attrs:{"cols":"9","md":"10"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.region_rencontres'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectTrainingLocation',{staticClass:"ml-3",attrs:{"type":_vm.$appConfig.trainingLocationTypeSubRegion,"label-size":"short","label":_vm.$t('car_dealer.form.region_rencontres'),"error-messages":errors,"disabled":_vm.readonly()},model:{value:(_vm.regionRencontres),callback:function ($$v) {_vm.regionRencontres=$$v},expression:"regionRencontres"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectTrainingLocation',{attrs:{"label":_vm.$t('car_dealer.form.address'),"error-messages":errors,"disabled":_vm.readonly()},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('car_dealer.form.training_locations')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectTrainingLocation',{attrs:{"type":_vm.$appConfig.trainingLocationTypeCarDealer,"label-size":"short","label":_vm.$t('car_dealer.form.training_locations'),"error-messages":errors,"disabled":_vm.readonly()},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})]}}])})],1)],1)],2)],1)],1),_c('cancel-update-dialog',{attrs:{"saving":_vm.isCarDealerSubmitting},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }