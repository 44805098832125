






































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { RawLocation, Location } from 'vue-router'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'CarDealer',
  components: {
    CancelUpdateDialog: () => import('../../../components/cancel-update-dialog.vue'),
    SelectTrainingLocation: () => import('@/components/molecules/select-search/location.vue'),
    TopBar,
  },
  data () {
    return {
      dialogDocument: false,
      title: this.$t('car_dealer.title'),
      showChangePassword: false,
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('carDealerForm', [
      'carDealer.code',
      'carDealer.name',
      'carDealer.carDealerRegion',
      'carDealer.brand',
      'carDealer.address',
      'carDealer.regionRencontres',
      'carDealer.location',
    ]),
    ...mapState('carDealerForm', {
      carDealer: 'carDealer',
      isCarDealerSubmitting: 'isSubmitting',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
  },
  async created () {
    await this.init()
  },
  watch: {
    $route () {
      this.init()
    },
  },
  methods: {
    ...mapActions('carDealerForm', {
      save: 'save',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    resetStore (to: Location) {
      if (!to.path || !to.path.startsWith('/car_dealer/')) {
        this.reset()
      }
    },
    async init () {
      await this.resetCarDealer()

      const idCarDealer = this.$route.params.idCarDealer
      if (idCarDealer) {
        await this.loadCarDealerById(idCarDealer)
      }
    },
    ...mapActions('carDealerForm', {
      loadCarDealerById: 'loadById',
      resetCarDealer: 'reset',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        await this.save()
        await this.sendSuccessMessage('car_dealer.form.saved')
        this.returnPrevious()
      }
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'CarDealer List' })
      }
    },
  },
})
